"use client";

import React, { useState } from "react";
import { z } from "zod";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { usePathname, useRouter } from "next/navigation";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { SubmitHandler, useForm } from "react-hook-form";
import CourseDropDownList from "./CourseDropDownList";
import { appConfig } from "@/src/config";
import { EnquirySchema } from "../schemas/enquirySchema";
import { sendOTP, verifyOTP } from "../actions/otp";
import { getBrochureDownloadableUrl } from "../actions/course";

export default function EnquiryForm({
  setOpen,
  action = "ThankYouMessage",
  brochureUrl,
}: {
  action?: "DownloadBrochure" | "ThankYouMessage";
  brochureUrl?: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const pathName = usePathname();
  const isLandingPage = pathName.includes("landing");
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const [otpStatus, setOtpStatus] = useState({ sent: false, verified: false });
  const [validationErrors, setValidationErrors] = React.useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<z.infer<typeof EnquirySchema>>({
    resolver: zodResolver(EnquirySchema),
    defaultValues: {
      course: isLandingPage && pathName,
      from_mail: appConfig.mailFrom,
      to_mail: isLandingPage ? appConfig.mail1 : appConfig.mail2,
      subject: isLandingPage ? "Landing Page Enquiry" : "Training Enquiry",
      summer_training: isLandingPage,
      location: "Ghaziabad",
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof EnquirySchema>> = async (
    data
  ) => {
    setValidationErrors(null);

    if (!otpStatus.sent) {
      await handleSendOTP(data.phone);
      return;
    }

    if (otpStatus.sent && !otpStatus.verified) {
      const verified = await handleVerifyOTP();
      if (!verified) return;
    }

    // const recaptchaVerified = await handleRecaptchaVerification();
    // if (!recaptchaVerified) return;

    const response = await fetch("/api/enquiry", {
      method: "POST",
      body: JSON.stringify({ ...data, recaptchaScore: 0 }),
      headers: { "Content-Type": "application/json" },
    });
    const responseResult = await response.json();
    if (!response.ok) {
      setValidationErrors(responseResult.errors);
      return;
    }

    if (responseResult.errors) {
      const errors = responseResult.errors;
      if (errors.name) {
        setError("name", {
          type: "server",
          message: errors.name,
        });
      }
      if (errors.email) {
        setError("email", {
          type: "server",
          message: errors.email,
        });
      }
      if (errors.phone) {
        setError("phone", {
          type: "server",
          message: errors.phone,
        });
      }
      if (errors.message) {
        setError("message", {
          type: "server",
          message: errors.message,
        });
      }
      if (errors.course) {
        setError("course", {
          type: "server",
          message: errors.course,
        });
      }
      if (errors.location) {
        setError("location", {
          type: "server",
          message: errors.location,
        });
      }
      return;
    }

    reset();
    setOpen && setOpen(false);
    if (action === "ThankYouMessage") {
      router.push("/thank-you");
    } else if (action === "DownloadBrochure") {
      if (brochureUrl) {
        const downloadableUrl = await getBrochureDownloadableUrl(brochureUrl);
        const link = document.createElement("a");
        link.href = `${downloadableUrl}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        router.push("/thank-you");
      }
    }
  };

  const handleSendOTP = async (phone: string) => {
    const result = await sendOTP(phone);
    if (result?.errors) {
      setValidationErrors(result.errors);
    } else {
      setOtpStatus((prev) => ({ ...prev, sent: true }));
    }
  };

  const handleVerifyOTP = async () => {
    const result = await verifyOTP(getValues("otp"), getValues("phone"));
    if (result?.errors) {
      setValidationErrors(result.errors);
      return false;
    }
    setOtpStatus((prev) => ({ ...prev, verified: true }));
    return true;
  };

  // const handleRecaptchaVerification = async () => {
  //   if (!executeRecaptcha) return false;
  //   const gReCaptchaToken = await executeRecaptcha("enquirySubmit");
  //   const response = await fetch("/api/recaptcha-verification", {
  //     method: "POST",
  //     body: JSON.stringify({ gReCaptchaToken }),
  //     headers: { "Content-Type": "application/json" },
  //   });
  //   const captchaData = await response.json();
  //   return captchaData.success ? captchaData.recaptchaScore : false;
  // };

  const handleCourseChange = (event, newValue) => {
    setValue("course", newValue?.label);
  };

  const handleLocationChange = (event: SelectChangeEvent) => {
    setValue("location", event.target.value as string);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      {!!validationErrors?.server && (
        <Alert sx={{ mb: 2 }} severity="error">
          {validationErrors?.server}
        </Alert>
      )}

      {!otpStatus.sent && (
        <>
          <TextField
            {...register("name")}
            fullWidth
            size="small"
            placeholder="Name"
            error={!!errors.name}
            helperText={errors.name?.message}
            sx={{ my: 2 }}
          />
          <TextField
            {...register("email")}
            fullWidth
            size="small"
            placeholder="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            {...register("phone")}
            fullWidth
            size="small"
            placeholder="Phone"
            error={!!errors.phone}
            helperText={errors.phone?.message}
            sx={{ my: 2 }}
          />
          <TextField
            {...register("message")}
            fullWidth
            size="small"
            placeholder="Message"
            multiline
            rows={2}
            error={!!errors.message}
            helperText={errors.message?.message}
            sx={{ mb: 2 }}
          />

          {!isLandingPage && (
            <Box sx={{ mb: 2 }}>
              <CourseDropDownList onChange={handleCourseChange} />
              {!!errors.course && (
                <FormHelperText error>{errors.course?.message}</FormHelperText>
              )}
            </Box>
          )}

          <FormControl error={!!errors.location} fullWidth sx={{ mb: 3 }}>
            <InputLabel id="location-select-label">Location</InputLabel>
            <Select
              size="small"
              defaultValue="Ghaziabad"
              labelId="location-select-label"
              onChange={handleLocationChange}
            >
              <MenuItem value="Online">Online</MenuItem>
              <MenuItem value="Ghaziabad">Ghaziabad</MenuItem>
            </Select>
            {!!errors.location && (
              <FormHelperText error>{errors.location?.message}</FormHelperText>
            )}
          </FormControl>
        </>
      )}

      {otpStatus.sent && !otpStatus.verified && (
        <TextField
          {...register("otp")}
          fullWidth
          size="small"
          placeholder="Enter OTP"
          error={!!errors.otp}
          helperText={errors.otp?.message}
          sx={{ mb: 2 }}
        />
      )}

      <Button
        disabled={isSubmitting}
        type="submit"
        variant="contained"
        fullWidth
      >
        {!otpStatus.sent
          ? "Book a demo"
          : !otpStatus.verified
          ? "Verify OTP"
          : "Verifying..."}
      </Button>
    </Box>
  );
}
