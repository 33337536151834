"use client";

import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { LucideIcon, Headset } from "lucide-react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import SpecialEnquiryFormWrapper from "./SpecialEnquiryFormWrapper";

export default function EnquiryButton({
  text,
  action,
  size = "medium",
  brochureUrl,
  Icon = Headset,
}: {
  text: string;
  size?: "small" | "medium" | "large";
  action: "DownloadBrochure" | "ThankYouMessage";
  brochureUrl?: string;
  Icon?: LucideIcon;
}) {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<Icon size={18} />}
        onClick={handleOpen}
        color="primary"
        variant="contained"
        size={size}
      >
        {text}
      </Button>
      {open && (
        <Dialog
          fullWidth
          scroll="body"
          maxWidth="sm"
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ m: 0, p: 0 }}>
            <SpecialEnquiryFormWrapper
              setOpen={setOpen}
              brochureUrl={brochureUrl}
              action={action}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
