"use client";

import React from "react";
import useSWR from "swr";
import { TextField, Autocomplete } from "@mui/material";

const fetcher = (url) => fetch(url).then((r) => r.json());

export default function CourseDropDownList({ onChange }) {
  const { data, error, isLoading } = useSWR("/api/courses", fetcher);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{error}</p>;
  }

  const options = data?.courses?.map((course) => {
    return {
      label: course.title,
      id: course._id,
    };
  });

  return (
    <Autocomplete
      size="small"
      disablePortal
      fullWidth
      onChange={onChange}
      options={options}
      isOptionEqualToValue={(option: any, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label="Course List" />}
    />
  );
}
