import { z } from "zod";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

export const EnquirySchema = z.object({
  name: z.string().min(2, "Name is required"),
  email: z.string().email("Invalid email").min(1, "Email is required"),
  phone: z.string().regex(phoneRegex, "Invalid Number!").length(10),
  message: z.string().min(2, "Message minimum length is 10"),
  summer_training: z.coerce.boolean().default(false),
  from_mail: z.string(),
  subject: z.string(),
  to_mail: z.string(),
  otp: z.string().length(6, "OTP must be exactly 6 characters").optional(),
  location: z.string().min(2, "Location is required"),
  course: z.string().optional(),
});
