import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Image from "next/image";
import EnquiryForm from "./EnquiryForm";

export default function SpecialEnquiryFormWrapper({
  setOpen,
  action,
  brochureUrl,
}: {
  action: "DownloadBrochure" | "ThankYouMessage";
  brochureUrl?: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Box p={2}>
      <Grid container columnSpacing={4}>
        <Grid item xs={11}>
          <Typography alignItems="center" fontWeight="600" sx={{ mb: 2 }}>
            Join Over more than &nbsp;
            <span style={{ color: "red" }}>7000</span>
            &nbsp; Students Enjoying Brain Mentors Training now.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <EnquiryForm
            setOpen={setOpen}
            action={action}
            brochureUrl={brochureUrl}
          />
        </Grid>
        <Grid display={{ xs: "none", sm: "block" }} item sm={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <Image
              alt="company-model"
              src="/images/company-model.webp"
              style={{ objectFit: "contain" }}
              fill
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
